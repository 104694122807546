<template>
  <div>
    <h1>Rechnungszeitraum</h1>
    <form @submit.prevent="createInvoice()">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="from">Monat auswählen</label>
            <input
              type="month"
              name="from"
              v-model="fromDate"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-dark" :disabled="submitting">
        <i class="fa fa-fw fa-spinner fa-pulse" v-if="submitting"></i>
        <span v-else>Erstellen</span>
      </button>
    </form>
  </div>
</template>

<script>
import { format } from 'date-fns';

import { functions } from '../firebase';

export default {
  data() {
    return {
      submitting: false,
      fromDate: format(Date.now(), 'yyyy-MM')
    };
  },
  methods: {
    async createInvoice() {
      this.submitting = true;

      const result = await functions.httpsCallable('httpsCreateInvoice')({
        fromDate: this.fromDate
      });

      const a = document.createElement('a');
      a.href =
        'data:attachment/csv;charset=utf-8,%EF%BB%BF' +
        encodeURIComponent(result.data.buchungsdatei);
      a.target = '_blank';
      a.download = `rechnung-${this.fromDate}.csv`;
      document.body.appendChild(a);
      a.click();

      const b = document.createElement('a');
      b.href =
        'data:attachment/csv;charset=utf-8,%EF%BB%BF' +
        encodeURIComponent(result.data.auszahlungsdatei);
      b.target = '_blank';
      b.download = `auszahlungsdatei-${this.fromDate}.csv`;
      document.body.appendChild(b);
      b.click();

      this.submitting = false;
    }
  }
};
</script>
